

// import Splide from "@splidejs/splide";

// import { tns } from "bxslider/dist/jquery.bxslider.min.js"

// window.onload = function() {
//     var blockSliderInterval = setInterval(function(){
//         //console.log('blockSliderInterval');
//         initBlock()
//     }, 200);
//     function initBlock(){
//         $('.slider .block-editor-block-list__layout').bxSlider();

//             clearInterval(blockSliderInterval);
//         }

// }